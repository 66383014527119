<template>
  <div>
    <ParallaxComponent 
      :selectedImage="selectedImage"
      :title="'Community Impact'"
      :paragraph="'At Acacia Wilderness Mweya, we positively impact local communities by supporting sustainable livelihoods and promoting cultural heritage through artisan crafts. Our guests help preserve these traditions while enjoying unique experiences that connect them with the region\'s rich culture.'"
    />

    <section>
      <article class="accommodation-body">
        <section>
          <article>
            <div class="room-accommodation-grid">
              <div>
                <img src="../../assets/images/community-impact-01.jpg" />
              </div>
              <div>
                <div data-aos="fade-zoom-in"
              data-aos-duration="1000"
           >
                  <h2
                 
                    style="font-size: 3em"
                  >
                    Empowering Local Artisans
                  </h2>
                  <p style="color: #188b33">
                    Supporting Craftsmanship and Local Communities
                  </p>
                  <p style="color: #747171">
                    We collaborate with skilled artisans from nearby villages
                    who craft beautiful handmade goods, from woven baskets to
                    intricate beadwork and carvings. These items are available
                    at our lodge, offering guests a unique opportunity to take
                    home authentic, locally made souvenirs while supporting the
                    artisans and their families
                  </p>
                </div>
              </div>
            </div>

            <div
              class="room-accommodation-grid-reserve"
              style="background-color: #fffcf1"
             
            >
              <div  data-aos="fade-zoom-in"
              data-aos-duration="500"
            
              data-aos-offset="0">
                <div>
                  <h2 style="font-size: 3em">Sustaining Fishing Villages</h2>
                  <p style="color: #188b33">
                    Preserving Heritage and Sustainable Fishing Practices
                  </p>
                  <p>
                    The fishing communities along the shores of Lake Edward are
                    an integral part of the park's cultural and economic
                    landscape. We work with these villages to promote
                    sustainable fishing practices, ensuring that their way of
                    life can continue for future generations. Guests at Acacia
                    Wilderness Mweya have the opportunity to visit these
                    villages, learn about traditional fishing methods, and
                    experience the rich culture of the local people.
                  </p>
                </div>
              </div>
              <div>
                <img src="../../assets/images/community-impact-02.png" />
              </div>
            </div>
          </article>
        </section>
      </article>
    </section>
  </div>
</template>
  
  <script>
  import ParallaxComponent from "../../components/website/pallarax-component.vue";
  import AOS from 'aos';
export default {
  components: {
    ParallaxComponent
  },

  data() {
    return {
      selectedImage: require("../../assets/images/community-impact-01.jpg"),
      loading: false,
      isLoading: false,
      categories: [],
      singleOccupancy: {},
      doubleOccupancy: {},
      familyOccupancy: {},
    };
  },

  mounted() {
    // this.getCategories();
    AOS.refresh();
    console.log("mounted");
  },



  methods: {
    async getCategories() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`categories`);
        if (
          request.data.success &&
          request.data.message == "ROOM_CATEGORIES_FETCHED_SUCCESSFULLY"
        ) {
          this.categories = request.data.categories;
          this.singleOccupancy = this.categories.filter((item) => {
            return item.userName === "single-occupancy";
          })[0];
          this.doubleOccupancy = this.categories.filter((item) => {
            return item.userName === "double-occupancy";
          })[0];
          this.familyOccupancy = this.categories.filter((item) => {
            return item.userName === "family-occupancy";
          })[0];
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        // this.$notify({
        //   title: "Operation Failed",
        //   message: "Unable to fetch categories now, please try again",
        //   type: "error",
        // });
      } finally {
        this.isLoading = false;
      }
    },
  },
  
};
</script>
  
  <style scoped>
.experiences-gallery {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}
.acacia-container {
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
}
.accommodation-header {
  background-image: url("../../assets/images/community-impact-01.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
  width: 100%;

  position: relative;
}

.accommodation-header > div {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.878) 100%
  );
  color: white;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 180px;
}

.accommodation-header > div > div {
  width: 60%;
}
.accommodation-header h1 {
  font-size: 3em;
  font-weight: 800;
}
.accommodation-header p {
  font-size: 1.5em;
  font-weight: 500;
}

.accommodation-body {
  padding-top: 50px;
  padding-bottom: 50px;
}
.accommodation-body h2 {
  color: #17351e;
  /* text-transform: uppercase; */
  font-size: 2em;
  font-weight: 500;
}

.room-accommodation-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.room-accommodation-grid > div {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.room-accommodation-grid > div p {
  font-size: 1em;
}

.room-accommodation-grid > div img {
  min-height: 80vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.room-accommodation-grid > div:nth-child(2) {
  padding: 50px;
}

.room-accommodation-grid-reserve {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.room-accommodation-grid-reserve > div {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.room-accommodation-grid-reserve > div img {
  min-height: 80vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.room-accommodation-grid-reserve > div:first-child {
  padding: 50px;
}
.acacia-btn {
  background-color: #17351e;
  border: 2px solid #17351e;
  color: #fff;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 50%;
}

.acacia-btn:hover {
  background-color: #ddd0a9;
  border: 2px solid #ddd0a9;
  color: white !important;
  transition: 0.5s;
}

.room-rate {
  width: 50%;
  margin-bottom: 30px;
}

.room-rate > div {
  border: 1px solid #ddd0a9;
  padding: 20px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
}

.room-rate > div:first-child {
  background-color: #ddd0a9;
  color: white;
}

.experiences-gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.experiences-gallery-grid > div {
  width: 100%;
  height: 200px;
  background-color: #ddd0a9;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
}
</style>
  